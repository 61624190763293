import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';

const Home = () => {
  const [videoLoaded, setVideoLoaded] = useState({
    demo1: false,
    demo2: false,
  });

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const questions = [
    "Struggling to manage prescriptions for your parents?",
    "Need healthcare in your native language?",
    "Looking for financial flexibility in healthcare?",
    "Ready to simplify healthcare for your family?",
  ];

  const features = [
    {
      title: 'Prescription Management',
      description: 'AI schedules smart medication reminders for timely adherence.',
      color: 'bg-blue-50'
    },
    {
      title: 'Multilingual Support',
      description: 'Interact with prescriptions in your native language.',
      color: 'bg-indigo-50'
    },
    {
      title: 'Financial Flexibility',
      description: 'Care Now, Pay Later with AI-enabled health loans.',
      color: 'bg-purple-50'
    },
    {
      title: 'Peace of Mind',
      description: 'Focus on your career while ensuring the best care for your parents.',
      color: 'bg-blue-50'
    },
  ];

  const companyStats = [
    { value: '98%', label: 'Employee Satisfaction' },
    { value: '45%', label: 'Reduced Healthcare Costs' },
    { value: '3.2x', label: 'ROI for Companies' },
    { value: '24/7', label: 'Support Available' },
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <section className="min-h-[90vh] flex items-center bg-white relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            {/* Left Content */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="max-w-xl"
            >
              {/* Enhanced Typewriter Questions */}
              <div className="h-32 mb-4">
                <div className="text-4xl lg:text-5xl font-bold tracking-tight">
                  <span className="bg-gradient-to-r from-blue-600 via-blue-500 to-indigo-600 bg-clip-text text-transparent">
                    <Typewriter
                      options={{
                        strings: questions,
                        autoStart: true,
                        loop: true,
                        delay: 75,
                        deleteSpeed: 50,
                        cursor: '|',
                        cursorClassName: 'text-blue-500 animate-pulse',
                        wrapperClassName: 'text-gradient'
                      }}
                    />
                  </span>
                </div>
              </div>

              {/* Add subtitle */}
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.8 }}
                transition={{ delay: 0.3 }}
                className="text-lg text-gray-600 mb-8"
              >
                Be among the first to experience our AI-powered platform designed for IT professionals and their families.
              </motion.p>

              {/* Trust indicators */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="flex items-center gap-6 mb-8"
              >
                <div className="flex items-center gap-2">
                  <span className="text-yellow-400"> A+ </span>
                  <span className="text-sm text-gray-600">SSL labs Rating </span>
                </div>
                <div className="flex items-center gap-2">
                  <svg 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-gray-600"
                  >
                    <path 
                      d="M12 2L3 7V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V7L12 2ZM12 12.99H19C18.47 17.11 15.72 20.78 12 21.93V13H5V7.3L12 3.19V12.99Z" 
                      fill="currentColor"
                    />
                  </svg>
                  <span className="text-sm text-gray-600">HIPAA Compliant</span>
                </div>
              </motion.div>

              {/* Enhanced CTA section */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.6 }}
                className="flex items-center gap-4"
              >
                <button className="group relative px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-full hover:from-blue-700 hover:to-blue-600 transition-all duration-300 shadow-lg hover:shadow-xl">
                  <span className="flex items-center gap-2 text-lg font-semibold">
                    Install App
                    <svg 
                      className="w-5 h-5 group-hover:translate-x-1 transition-transform" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M13 7l5 5m0 0l-5 5m5-5H6" 
                      />
                    </svg>
                  </span>
                </button>
                <button className="px-8 py-4 text-blue-600 hover:bg-blue-50 rounded-full transition-colors">
                  Learn More
                </button>
              </motion.div>
            </motion.div>

            {/* Right Content - Video Preview */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
              className="flex justify-center items-center"
            >
              <div className="relative w-[320px] lg:w-[380px]">
                <div className="relative overflow-hidden rounded-3xl">
                  {!videoLoaded.demo1 && (
                    <div className="absolute inset-0 bg-gray-100 animate-pulse" />
                  )}
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    onLoadedData={() => setVideoLoaded(prev => ({ ...prev, demo1: true }))}
                    className={`w-full h-full object-cover transition-opacity duration-500 ${
                      videoLoaded.demo1 ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <source src="/app-demo-1.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Scroll indicator */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: scrolled ? 0 : 1 }}
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
        >
          <div className="flex flex-col items-center gap-2 text-gray-400">
            <span className="text-sm">Scroll to explore</span>
            <motion.div
              animate={{ y: [0, 8, 0] }}
              transition={{ repeat: Infinity, duration: 1.5 }}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg>
            </motion.div>
          </div>
        </motion.div>
      </section>

      {/* Enhanced Company Message Section */}
      <section className="py-24 relative">
        {/* Background Image */}
        <div 
          className="absolute inset-0 bg-cover bg-center bg-fixed opacity-10"
          style={{ backgroundImage: 'url("/corporate-bg.jpg")' }}
        />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6">
              Transform Your Corporate Healthcare
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Empower your workforce with a comprehensive healthcare solution that drives productivity, 
              reduces costs, and shows you care about your employees' families.
            </p>
          </motion.div>

          {/* Stats Grid */}
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
            {companyStats.map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="text-center p-6 bg-white rounded-xl shadow-lg"
              >
                <div className="text-3xl font-bold text-blue-600 mb-2">
                  {stat.value}
                </div>
                <div className="text-gray-600">
                  {stat.label}
                </div>
              </motion.div>
            ))}
          </div>

          {/* Benefits Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="bg-white p-8 rounded-xl shadow-lg"
            >
              <h3 className="text-xl font-semibold mb-4">Cost Optimization</h3>
              <p className="text-gray-600">Reduce healthcare expenses through AI-driven preventive care and bulk pricing.</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
              className="bg-white p-8 rounded-xl shadow-lg"
            >
              <h3 className="text-xl font-semibold mb-4">Enhanced Productivity</h3>
              <p className="text-gray-600">Employees focus better knowing their families have access to quality healthcare.</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="bg-white p-8 rounded-xl shadow-lg"
            >
              <h3 className="text-xl font-semibold mb-4">Talent Retention</h3>
              <p className="text-gray-600">Stand out with comprehensive family healthcare benefits.</p>
            </motion.div>
          </div>

          {/* CTA Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <button className="px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-full hover:from-blue-700 hover:to-blue-600 transition-all duration-300 shadow-lg hover:shadow-xl">
              Schedule a Demo
            </button>
            <p className="mt-4 text-gray-600">
              Join 1000s of employee health conscious companies transforming their healthcare benefits
            </p>
          </motion.div>
        </div>
      </section>

      {/* Features Grid */}
      <section className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl lg:text-4xl font-bold text-gray-900">
              Simple Steps to Health Success
            </h2>
          </motion.div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className={`${feature.color} p-8 rounded-2xl hover:shadow-lg transition-all hover:-translate-y-1`}
              >
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* App Preview Section */}
      <section className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-blue-600 rounded-3xl p-12 text-white">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
              >
                <h2 className="text-4xl lg:text-5xl font-bold mb-6">
                  Comprehensive Health Search
                </h2>
                <p className="text-xl opacity-90 mb-8">
                  Find everything you need to know about your health in one place.
                </p>
                <button className="px-8 py-4 bg-white text-blue-600 rounded-full hover:bg-blue-50 transition-colors">
                  Try Now
                </button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
                className="relative overflow-hidden rounded-xl shadow-xl"
              >
                {!videoLoaded.demo2 && (
                  <div className="absolute inset-0 bg-gray-100 animate-pulse" />
                )}
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  onLoadedData={() => setVideoLoaded(prev => ({ ...prev, demo2: true }))}
                  className={`w-full h-full object-cover transition-opacity duration-500 ${
                    videoLoaded.demo2 ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <source src="/app-demo-2.mp4" type="video/mp4" />
                </video>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
import React from 'react';
import { motion } from 'framer-motion';

const Blog = () => {
  const featuredPost = {
    title: 'The Future of Healthcare: AI and Personal Health Monitoring',
    excerpt:
      'Discover how artificial intelligence is revolutionizing personal health monitoring and what it means for your healthcare journey.',
    image: '/blog/featured.jpg',
    author: 'Dr. Sarah Johnson',
    date: 'May 15, 2023',
    category: 'Healthcare Technology',
  };

  const recentPosts = [
    {
      title: '5 Ways to Make the Most of Your Health Tracking App',
      excerpt:
        'Learn how to utilize all the features of your health tracking app to improve your wellness journey.',
      image: '/blog/post1.jpg',
      author: 'Mike Chen',
      date: 'May 10, 2023',
      category: 'Tips & Tricks',
    },
    {
      title: 'Understanding Your Health Metrics: A Complete Guide',
      excerpt:
        'A comprehensive guide to understanding and interpreting your health metrics for better health outcomes.',
      image: '/blog/post2.jpg',
      author: 'Dr. Emily White',
      date: 'May 8, 2023',
      category: 'Health Education',
    },
    {
      title: 'The Role of Mental Health in Overall Wellness',
      excerpt:
        'Exploring the connection between mental and physical health, and how to track both effectively.',
      image: '/blog/post3.jpg',
      author: 'Dr. James Wilson',
      date: 'May 5, 2023',
      category: 'Mental Health',
    },
  ];

  return (
    <div className="pt-16">
      {/* Header */}
      <div className="bg-primary-900 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-4">Health & Wellness Blog</h1>
          <p className="text-xl text-primary-100">
            Insights, tips, and news about health monitoring and wellness
          </p>
        </div>
      </div>

      {/* Featured Post */}
      <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="bg-white rounded-lg shadow-xl overflow-hidden"
        >
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="relative h-64 lg:h-auto">
              <img
                src={featuredPost.image}
                alt={featuredPost.title}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
            <div className="p-8">
              <div className="text-sm text-primary-600 mb-2">
                {featuredPost.category}
              </div>
              <h2 className="text-2xl font-bold mb-4">{featuredPost.title}</h2>
              <p className="text-gray-600 mb-6">{featuredPost.excerpt}</p>
              <div className="flex items-center text-sm text-gray-500">
                <span>{featuredPost.author}</span>
                <span className="mx-2">•</span>
                <span>{featuredPost.date}</span>
              </div>
            </div>
          </div>
        </motion.div>
      </section>

      {/* Recent Posts */}
      <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-2xl font-bold mb-8">Recent Posts</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {recentPosts.map((post, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="relative h-48">
                <img
                  src={post.image}
                  alt={post.title}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <div className="text-sm text-primary-600 mb-2">
                  {post.category}
                </div>
                <h3 className="text-xl font-bold mb-3">{post.title}</h3>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <div className="flex items-center text-sm text-gray-500">
                  <span>{post.author}</span>
                  <span className="mx-2">•</span>
                  <span>{post.date}</span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Newsletter Section */}
      <section className="bg-primary-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl font-bold mb-4">
            Stay Updated with Health Tips
          </h2>
          <p className="text-gray-600 mb-8">
            Subscribe to our newsletter for the latest health insights and app updates
          </p>
          <div className="max-w-md mx-auto">
            <form className="flex gap-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
              <button
                type="submit"
                className="px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog; 
import React from 'react';
import { motion } from 'framer-motion';
import {
  FaHeartbeat,
  FaBrain,
  FaUserMd,
  FaChartLine,
  FaBell,
  FaLock,
  FaMobileAlt,
  FaCloudUploadAlt,
} from 'react-icons/fa';

const Features = () => {
  const features = [
    {
      icon: FaHeartbeat,
      title: 'Health Monitoring',
      description:
        'Track vital signs, activity levels, and other health metrics in real-time with our advanced monitoring system.',
    },
    {
      icon: FaBrain,
      title: 'AI-Powered Insights',
      description:
        'Get personalized health recommendations and insights powered by our advanced artificial intelligence system.',
    },
    {
      icon: FaUserMd,
      title: 'Connect with Doctors',
      description:
        'Instantly connect with healthcare professionals for virtual consultations and medical advice.',
    },
    {
      icon: FaChartLine,
      title: 'Progress Tracking',
      description:
        'View detailed charts and analytics of your health progress over time with easy-to-understand visualizations.',
    },
    {
      icon: FaBell,
      title: 'Smart Reminders',
      description:
        'Never miss a medication or appointment with our intelligent reminder system.',
    },
    {
      icon: FaLock,
      title: 'Secure & Private',
      description:
        'Your health data is protected with military-grade encryption and strict privacy controls.',
    },
    {
      icon: FaMobileAlt,
      title: 'Cross-Platform',
      description:
        'Access your health data from any device with our seamless cross-platform synchronization.',
    },
    {
      icon: FaCloudUploadAlt,
      title: 'Cloud Backup',
      description:
        'Your health data is automatically backed up to the cloud for safe keeping and easy access.',
    },
  ];

  return (
    <div className="pt-16">
      {/* Header */}
      <div className="bg-gradient-to-r from-primary-600 to-primary-800 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl md:text-5xl font-bold mb-6"
          >
            Powerful Features for Your Health
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl text-primary-100 max-w-3xl mx-auto"
          >
            Discover all the ways our app can help you monitor, track, and improve
            your health with these powerful features.
          </motion.p>
        </div>
      </div>

      {/* Features Grid */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  className="bg-white rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow"
                >
                  <div className="text-primary-600 mb-4">
                    <Icon className="h-8 w-8" />
                  </div>
                  <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </motion.div>
              );
            })}
          </div>
        </div>
      </section>

      {/* App Preview Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h2 className="text-3xl font-bold mb-6">
                Experience the Future of Health Monitoring
              </h2>
              <p className="text-gray-600 mb-8">
                Our app combines cutting-edge technology with an intuitive interface
                to give you the best possible health monitoring experience. Try it
                today and see the difference for yourself.
              </p>
              <div className="flex flex-wrap gap-4">
                <button className="px-6 py-3 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors">
                  Download Now
                </button>
                <button className="px-6 py-3 border border-primary-600 text-primary-600 rounded-lg hover:bg-primary-50 transition-colors">
                  Learn More
                </button>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="relative"
            >
              <img
                src="/app-preview-features.png"
                alt="App Preview"
                className="rounded-lg shadow-2xl"
              />
            </motion.div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-primary-900 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-8">
            Ready to Take Control of Your Health?
          </h2>
          <p className="text-xl text-primary-100 mb-12 max-w-3xl mx-auto">
            Join thousands of users who are already benefiting from our powerful
            health monitoring features.
          </p>
          <button className="px-8 py-4 bg-white text-primary-900 rounded-lg text-lg font-semibold hover:bg-primary-50 transition-colors">
            Get Started for Free
          </button>
        </div>
      </section>
    </div>
  );
};

export default Features; 